import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import GenerateSlug from '../../utils/GenerateSlug';
import CardActions from '../Social/CardActions';
import CommentList from '../Comments/CommentList'; 
import Linkify from 'react-linkify';
var dayjs = require ('dayjs');
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(relativeTime)


export default function PostCard(props) {

    const [images, setImages] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [commentsCount, setCommentsCount] = useState(props.commentsCount);
    const [mounted, setMounted] = useState(false);
    const [displayDate, setDisplayDate] = useState();
    // flair border styling     
    const [borderWidth, setBorderWidth] = useState(0);
    const [borderColor, setBorderColor] = useState('white');

    //const [borderWidth, setBorderWidth] = useState(8);
    //const [borderColor, setBorderColor] = useState('violet-400');
    //const [borderWidth, setBorderWidth] = useState(8);
    //const [borderColor, setBorderColor] = useState('red-200');


    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
      };

    const incrementCommentsCount = () => {
        setCommentsCount(commentsCount+1);
    }

    const showCommentsBlock = () => {
        setShowComments(!showComments);
    }

    useEffect( () => {
        // set post date

        
        var postDate = dayjs(props.createdAt).utc();

        var isToday = false;
        var format = 'D MMM YYYY';
        if (postDate.isSame(dayjs().utc(), 'day')) {
            isToday = true;
        }
        // set post date format depending if it is the same year or not
        if (postDate.isSame(dayjs().utc(), 'year')) {
            format = 'D MMM';
        }
        

        if (isToday) {
            setDisplayDate(postDate.fromNow())
        }
        else {
            setDisplayDate(postDate.format(format))
        }

        // prep images
        if (props.images) {
            const tmpImages = props.images.split(',');
            setImages(tmpImages);
            
        }
        
        setMounted(true);

    }, []); 



if (!mounted) return (null);
else
  return (
    <>
    <div className={'flex ' + ((showComments || props.noBottomSpace )? '' : 'mb-10 ')}>
        {/* social side */}
        <CardActions onDelete={props.onDelete} showCommentsBlock={showCommentsBlock} commentsCount={commentsCount} claps={props.claps} postId={props.id} cardType='post' username={props.username}/>

        {/* the post card :mb-10 pb-4 mr-0*/}
        <div className={'w-full pb-4 bg-white ' + (showComments ? `rounded-tl-xl rounded-tr-xl border-l-${borderWidth} border-t-${borderWidth} border-r-${borderWidth} border-${borderColor} shadow-md` : `border-${borderWidth} border-${borderColor} rounded-xl shadow-md`) } >        
            <div className='w-full pb-0 flex '>
                <div className='float-left'>

                    <div className="mx-4 my-4 flex">     
                        <div className='float-left flex-shrink-0'>       
                            <Link to={'/@' + props.username}>                                
                                <img className="rounded-full float-left h-12 w-12" src={props.avatar && props.avatar != 'undefined'? "https://ik.imagekit.io/makers/avatars/" + props.avatar + '?tr=w-160,h-160,cm-pad_resize,bg-FFFFFF': 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} /> 
                            </Link>
                        </div>
                        <div className='float:left'>
                            <span className=" ml-2 h-full text-black text-lg font-bold"><Link to={'/@' + props.username} className='flex-item'>{props.fullName && props.fullName != 'undefined' ? props.fullName: '@' + props.username}</Link></span>
                            <Link to={'/post/' + props.id} className='text-gray-400 text-xs ml-2 h-full' title={props.createdAt}>                                
                            <span className='pb-4'>•</span><span className='ml-2'>{displayDate}</span>
                            </Link>
                            <span className='flex pl-2 text-gray-400 text-sm'>{props.bio && props.bio !='undefined' ? props.bio : ''}</span>
                        </div>
                    </div>

                    <div className='block w-full'>
                        <p className='text-gray-700 mx-4 '>
                            <span className='mr-4 whitespace-pre-line'>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" className='underline text-blue-600 hover:text-blue-800 focus:outline-none' href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>{props.message}</Linkify>
                            </span>
                            {props.projectId && props.projectId !== 'null' && props.projectId !== 'undefined'? <Link to={"/project/" + GenerateSlug (props.projectId, props.projectName)} className='bg-yellowLight px-1 py-1 rounded-md text-blue-600  hover:text-blue-800 whitespace-nowrap'>#{props.projectName}</Link> : ''}
                        </p> 
                    </div>

                    {images ?
                    <>
                    <div className='flex space-x-2 mx-4 mt-2'>
                        {images.map(function(img, index){
                        return (
                            <div className='relative' key={index}>
                                <a href={'https://ik.imagekit.io/makers/posts/' + img} target='_blank'>
                                    <img key={index} src={'https://ik.imagekit.io/makers/posts/' + img + '?tr=w-320,c-at_max'} className={'align-middle ' +(images.length >1 ? 'w-72' : '')} />      
                                </a>
                            </div>
                            )
                        }
                        )}              
                    
                    </div>
                    
                    {/** 
                    <div className=''>
                        <StyledSlider dots={false} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1}>
                        {images.map(function(img, index){
                            return (
                                <div key={index}>
                                    <img src={'https://ik.imagekit.io/makers/posts/' + img + '?tr=w-320,c-at_max'} className={'align-middle ' +(images.length >1 ? 'w-72' : '')} />      
                                </div>
                                )
                            }
                            )}  
                        </StyledSlider>            
                    </div>
*/}
                    </>
                    : ''}
                    </div>
                
            </div>


        </div>
       
  </div>
  
    {/* comments */}
    {showComments ? 
        <div className='flex'>
            <div className='w-12 mr-4 sm:mr-6'></div>
            <div className={`comments w-full rounded-b-xl border-l-${borderWidth} border-b-${borderWidth} border-r-${borderWidth} border-${borderColor} shadow-md pl-4 py-2 bg-white ` + (props.noBottomSpace ? '' : 'mb-10 ')}>                
                <CommentList incrementCommentsCount={incrementCommentsCount} id={props.id} userId={props.userId} cardType='post'/>
            </div>
        </div>
     : ''}


  </>
  );


}

